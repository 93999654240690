

@charset "UTF-8";

@import 'abstracts/variables.scss';
@import 'components/shared.scss';
@import'components/text.scss';
@import'components/button.scss';
body {
    margin: 0;
}

@import 'bootstrap/dist/css/bootstrap.min.css';